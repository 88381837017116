import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Input, Label } from 'reactstrap';
import { RangeDatePicker } from '..';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/Calendar.svg';
import LoadingButton from '../../../../components/LoadingButton/LoadingButton';
import Picker from '../../../../components/Picker/Picker';
import { gtmBookingInteraction } from '../../../../gtm/events';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import { setCurrentCalendarMonth } from '../../../../redux/slices/availabilitySlice/availabilitySlice';
import GuestsField from '../GuestsField/GuestsField';
import SpecialCode from '../SpecialCode/SpecialCode';
import styles from './SelectRoomPicker.module.css';

const SelectRoomPicker = ({ loading, productCode, handleFormSubmit }) => {
  const { t } = useTranslate();
  const specialCodeTypes = {
    promoCode: t('Promo Code'),
    groupCode: t('Group Code'),
    agentId: t('Agent Id'),
  };

  const moment = useLocalisedMoment();
  const form = useFormContext();
  const { watch, handleSubmit } = form;
  const dispatch = useDispatch();

  const [calendarFocusedInput, setCalendarFocusedInput] = useState(null);
  const [showGuestsPicker, setShowGuestsPicker] = useState(false);

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const specialCodeType = watch('specialCodeType');
  const specialCodeValue = watch('specialCodeValue');
  const agentId = watch('agentId');
  const guestsPerRoom = watch('guestsPerRoom');

  const numAdults = useMemo(
    () => guestsPerRoom.reduce((acc, room) => acc + room.numAdults, 0),
    [guestsPerRoom]
  );

  const numChildren = useMemo(
    () => guestsPerRoom.reduce((acc, room) => acc + room.numChildren, 0),
    [guestsPerRoom]
  );

  const renderSpecialCodeInputValue = () => {
    if (specialCodeValue && agentId) {
      return `${specialCodeTypes[specialCodeType]}, ${t('Agent Id')}`;
    } else if (specialCodeValue) {
      return `${specialCodeTypes[specialCodeType]}: ${specialCodeValue}`;
    } else if (agentId) {
      return `${t('Agent Id')}: ${agentId}`;
    } else {
      return `${t('Special code?')}`;
    }
  };

  const handleGuestsFieldDone = (triggerCalendar) => {
    setShowGuestsPicker(false);
    if (triggerCalendar) {
      setCalendarFocusedInput('startDate');
    }
  };

  const handleSpecialCodesApply = () => {
    setCalendarFocusedInput('startDate');
  };

  const handleCalendarClose = () => {
    setCalendarFocusedInput(null);
    const formattedStartDate = moment(startDate).format('YYYY-MM');
    dispatch(setCurrentCalendarMonth(formattedStartDate));
  };

  useEffect(() => {
    if (calendarFocusedInput && window.innerHeight < 740) {
      const element = document.getElementById('calendar');
      const rect = element.getBoundingClientRect();
      const margin = window.innerHeight > 600 ? 75 : 0;
      const scrollTop = window.scrollY + rect.top - margin;
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
  }, [calendarFocusedInput]);

  return (
    <div className="container-xxl position-relative">
      <div
        className="d-flex  justify-content-end align-items-end gap-5"
        style={{ padding: '20px 0 30px 0' }}
      >
        <div className="w-25">
          <Picker
            className="w-100"
            shown={showGuestsPicker}
            onClose={handleGuestsFieldDone}
            picker={
              <div className="w-100">
                <Label className={styles.SelectRoomPicker__label}>
                  {t('Rooms & Guests')}
                </Label>
                <Input
                  type="select"
                  className={styles.SelectRoomPicker__select}
                  disabled={true}
                  aria-label="Change Guests"
                >
                  <option>
                    {guestsPerRoom.length}{' '}
                    {guestsPerRoom.length > 1 ? t('rooms') : t('room')},{' '}
                    {numAdults} {numAdults > 1 ? t('adults') : t('adult')}
                    {numChildren > 0 &&
                      `, ${numChildren} ${
                        numChildren > 1 ? t('children') : t('child')
                      }`}
                  </option>
                </Input>
              </div>
            }
            content={(close) => (
              <GuestsField
                onClose={close}
                onDone={(changedGuests) => {
                  changedGuests && handleSubmit(handleFormSubmit)();
                  handleGuestsFieldDone(true);
                  close();
                }}
                productCode={productCode}
              />
            )}
          />
        </div>

        <button
          className="w-25"
          type="button"
          onClick={() => setCalendarFocusedInput('startDate')}
        >
          <Label className={styles.SelectRoomPicker__label}>{t('Dates')}</Label>
          <div className="position-relative" aria-label="Open check-in">
            <Input
              style={{
                cursor: 'pointer',
              }}
              type="select"
              disabled={true}
              className={`${styles.SelectRoomPicker__select} disableSelectArrow OneLinkNoTx`}
              aria-label="Select check-in date"
            >
              <option>
                {moment(startDate).format('ddd, MMM D')} -{' '}
                {endDate ? moment(endDate).format('ddd, MMM D, YYYY') : ''}{' '}
              </option>
            </Input>
            <CalendarIcon className={styles.SelectRoomPicker__svg} />
          </div>
        </button>

        {!showGuestsPicker && !!calendarFocusedInput && (
          <div className={styles.SelectRoomPicker__calendar} id="calendar">
            <RangeDatePicker
              onClose={handleCalendarClose}
              productCode={productCode}
              startDate={startDate}
              endDate={endDate}
              focus={calendarFocusedInput}
              onChangeFocus={(calendarFocusedInput) => {
                setCalendarFocusedInput(calendarFocusedInput);
              }}
              months={2}
              daySize={60}
            />
          </div>
        )}

        <div>
          <Picker
            className="text-nowrap"
            pickerShownClassName={
              styles.SelectRoomPicker__specialCodesInputFocus
            }
            picker={
              <div
                className={styles.SelectRoomPicker__specialCodesInput}
                aria-label="select special code"
              >
                {renderSpecialCodeInputValue()}
              </div>
            }
            content={(close) => (
              <SpecialCode
                productCode={productCode}
                onClose={close}
                onApply={(ratePlanAssociated) => {
                  handleSpecialCodesApply();
                  handleSubmit(handleFormSubmit)({ ratePlanAssociated });
                  close();
                }}
                onRemoveCode={() => {
                  handleSpecialCodesApply();
                  handleSubmit(handleFormSubmit)({ ratePlanAssociated: false });
                }}
              />
            )}
          />
        </div>

        <LoadingButton
          type="submit"
          // do this, as well as calling the submit fn in the parent component
          onClick={() => gtmBookingInteraction('Search')}
          className={`button ${!form.formState.isDirty && 'transparent'}`}
          ariaLabel="Search rooms"
          loading={loading}
        >
          {t('Search')}
        </LoadingButton>
      </div>
    </div>
  );
};

SelectRoomPicker.propTypes = {
  loading: PropTypes.bool,
  productCode: PropTypes.string,
  handleFormSubmit: PropTypes.func,
};

export default SelectRoomPicker;
