import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as GreenCheckIcon } from '../../../assets/images/icons/greenCheck.svg';
import useTranslate from '../../../hooks/useTranslate/useTranslate';
import { Icon } from '../../../views/SelectRoom/components/RoomRate/components';
import NightlyRates from '../../NightlyRates/NightlyRates';
import styles from './BookingsCartRoomRate.module.css';

const BookingsCartRoomRate = ({
  rateContent,
  rateName,
  ratePrice,
  rateCurrencyCode,
  pricesPerNight,
  ratePriceBeforeTax,
  productCode,
  agentCommission,
}) => {
  const { t } = useTranslate();
  return (
    <div className="d-flex flex-column w-100">
      <div className="w-100 my-2 d-flex align-items-start">
        <div style={{ marginRight: 10 }}>
          <Icon rateContent={rateContent} />
        </div>
        <NightlyRates
          title={rateName}
          totalPrice={ratePrice}
          currencyCode={rateCurrencyCode}
          pricesPerNight={pricesPerNight}
          priceBeforeTax={ratePriceBeforeTax}
          productCode={productCode}
        />
      </div>

      {agentCommission && (
        <div className={styles.BookingsCartRoomRate__container}>
          <div className={styles.BookingsCartRoomRate__icon}>
            <GreenCheckIcon />
          </div>
          <div>
            <div className={styles.BookingsCartRoomRate__description}>
              {`${Math.round(agentCommission)}% ${t('commission per night')}`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

BookingsCartRoomRate.propTypes = {
  rateName: PropTypes.string,
  ratePrice: PropTypes.number,
  rateCurrencyCode: PropTypes.string.isRequired,
  pricesPerNight: PropTypes.array,
  ratePriceBeforeTax: PropTypes.number,
  productCode: PropTypes.string,
  agentCommission: PropTypes.number,
  rateContent: PropTypes.object,
};

export default BookingsCartRoomRate;
