import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios/useAxios';
import useLocalisedMoment from '../../../hooks/useLocalisedMoment/useLocalisedMoment';

// expected search param formats:
// adults=5&adults=2 --> [5, 2]
// children=3,4,5&children=1,2 --> [[3, 4, 5], [1, 2]]
// numRooms=3 --> 3
// startDate=2021-08-01 --> 2021-08-01
// endDate=2021-08-02 --> 2021-08-02
// agentId=123 --> 123
// specialCodeType=AAA --> AAA
// specialCodeValue=BBB --> BBB
// rateCode=CCC --> CCC

const useDetermineSelectRoomSearchParameters = (hotel) => {
  const axios = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  const moment = useLocalisedMoment();
  const numRooms = useRef();
  const [specialCodeType, setSpecialCodeType] = useState('');
  const [specialCodeValue, setSpecialCodeValue] = useState('');
  const [rateCode, setRateCode] = useState('');
  const bookings = useSelector((state) => state.bookings.list);
  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const [endDateOverride, setEndDateOverride] = useState(null);

  let adultsFromParams = searchParams.getAll('adults');
  let childrenFromParams = searchParams
    .getAll('children')
    .map((arr) => arr.split(','));

  // if adults or children contain items that are not a number
  // set them to empty arrays
  // otherwise cast to numbers

  if (adultsFromParams.some((val) => isNaN(val))) {
    adultsFromParams = [];
  } else {
    adultsFromParams = adultsFromParams.map((val) =>
      Number(val) <= hotel.maximumNumberOfGuests.adults
        ? Number(val)
        : hotel.maximumNumberOfGuests.adults
    );
  }

  if (childrenFromParams.some((arr) => arr.some((val) => isNaN(val)))) {
    childrenFromParams = [];
  } else {
    childrenFromParams = childrenFromParams.map((arr) => {
      if (arr.length > hotel.maximumNumberOfGuests.children) {
        return arr
          .slice(0, hotel.maximumNumberOfGuests.children)
          .map((val) => Number(val));
      } else {
        return arr.map((val) => Number(val));
      }
    });
  }

  numRooms.current =
    bookings.length ||
    Math.min(
      Math.max(adultsFromParams.length, searchParams.get('numRooms')),
      hotel.totalRooms > 49 ? 5 : 3
    ) ||
    1;

  const guestsPerRoom = useMemo(() => {
    const guestsPerRoomFromBookings = bookings.map(
      ({ numAdults, children }, i) => ({
        numAdults,
        numChildren: children.length,
        children,
      })
    );

    if (numRooms.current > bookings.length) {
      const roomsToPopulate = numRooms.current - bookings.length;

      const populatedGuestsPerRoom = Array.from({
        length: roomsToPopulate,
      }).map((_, i) => {
        let numAdults, children;
        if (bookings.length) {
          numAdults = 2;
          children = [];
        } else {
          numAdults = adultsFromParams[i] || 2;
          children = childrenFromParams[i] || [];
        }

        return { numAdults, children, numChildren: children.length };
      });

      return [...guestsPerRoomFromBookings, ...populatedGuestsPerRoom];
    } else {
      return guestsPerRoomFromBookings;
    }
  }, [adultsFromParams, bookings, childrenFromParams, numRooms]);

  const defaultValues = useMemo(
    () => ({
      guestsPerRoom,
      startDate:
        searchParams.get('startDate') ||
        bookings[0]?.startDate ||
        moment().add(1, 'day').format('YYYY-MM-DD'),
      endDate:
        endDateOverride ||
        searchParams.get('endDate') ||
        bookings[0]?.endDate ||
        moment().add(2, 'days').format('YYYY-MM-DD'),
      agentId: searchParams.get('agentId') || bookings[0]?.agentId || '',
      agentCrmId:
        searchParams.get('contact') ||
        searchParams.get('agentCrmId') ||
        bookings[0]?.agentCrmId ||
        '',
      specialCodeType: specialCodeType || bookings[0]?.specialCodeType || '',
      specialCodeValue: specialCodeValue || bookings[0]?.specialCodeValue || '',
      rateCode: rateCode || '',
    }),
    [
      guestsPerRoom,
      searchParams,
      bookings,
      endDateOverride,
      specialCodeType,
      specialCodeValue,
      rateCode,
      moment,
    ]
  );

  // if the start date is before today
  if (moment(defaultValues.startDate).isBefore(moment(), 'day')) {
    defaultValues.startDate = moment().format('YYYY-MM-DD');
  }
  //if the end date is before day after start date
  if (
    moment(defaultValues.endDate).isBefore(
      moment(defaultValues.startDate).add(1, 'day'),
      'day'
    )
  ) {
    defaultValues.endDate = moment(defaultValues.startDate)
      .add(1, 'day')
      .format('YYYY-MM-DD');
  }

  const validateSpecialCode = useCallback(async () => {
    if (!countryCode) return;

    const specialCodeValue = searchParams
      .get('specialCodeValue')
      ?.toUpperCase();

    const params = {
      productCode: hotel.productCode,
      startDate: defaultValues.startDate,
      numAdults: 2, // just use 1 room and 2 adults to check code validitiy
      specialCodeType: searchParams.get('specialCodeType'),
      specialCodeValue,
      agentId: defaultValues.agentId,
      countryCode,
    };

    try {
      const {
        data: { isValid, lowestMinStay },
      } = await axios.get('/validate-special-code', {
        params,
      });

      if (!isValid) {
        const params = new URLSearchParams(searchParams);
        params.delete('specialCodeType');
        params.delete('specialCodeValue');
        setSearchParams(params);
      } else {
        setSpecialCodeType(searchParams.get('specialCodeType'));
        setSpecialCodeValue(specialCodeValue);
      }
      if (lowestMinStay) {
        setEndDateOverride(
          moment(defaultValues.startDate)
            .add(lowestMinStay, 'days')
            .format('YYYY-MM-DD')
        );
      }

      return isValid;
    } catch (e) {
      const params = new URLSearchParams(searchParams);
      params.delete('specialCodeType');
      params.delete('specialCodeValue');
      setSearchParams(params);

      return false;
    }
  }, [
    defaultValues.agentId,
    defaultValues.startDate,
    hotel.productCode,
    moment,
    searchParams,
    setSearchParams,
    axios,
    countryCode,
  ]);

  const validateRateCode = useCallback(async () => {
    // TODO: check if we need agent ID for rate code validation
    const params = {
      productCode: hotel.productCode,
      startDate: defaultValues.startDate,
      numAdults: 2, // just use 1 room and 2 adults to check code validitiy
      rateCode: searchParams.get('rateCode'),
    };
    try {
      const {
        data: { isValid, lowestMinStay },
      } = await axios.get('/validate-rate-code', {
        params,
      });

      if (!isValid) {
        const params = new URLSearchParams(searchParams);
        params.delete('rateCode');
        setSearchParams(params);
      } else {
        setRateCode(searchParams.get('rateCode'));
      }
      if (lowestMinStay) {
        setEndDateOverride(
          moment(defaultValues.startDate)
            .add(lowestMinStay, 'days')
            .format('YYYY-MM-DD')
        );
      }

      return isValid;
    } catch (e) {
      const params = new URLSearchParams(searchParams);
      params.delete('rateCode');
      setSearchParams(params);

      return false;
    }
  }, [
    defaultValues.startDate,
    hotel.productCode,
    moment,
    searchParams,
    setSearchParams,
    axios,
  ]);

  return { defaultValues, validateSpecialCode, validateRateCode };
};

export default useDetermineSelectRoomSearchParameters;
