import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useLocalisedMoment from '../../../hooks/useLocalisedMoment/useLocalisedMoment';
import usePrintPrice from '../../../hooks/usePrintPrice/usePrintPrice';
import { setCurrentCalendarMonth } from '../../../redux/slices/availabilitySlice/availabilitySlice';
import DayContents from '../components/RangeDatePicker/components/DayContents/DayContents';
import {
  checkIfDisabledDateIsPicked,
  getAvailableSelectRange,
  getIsDayBlocked,
  getSelectedRange,
  getSelectedRangeAfterRestrictions,
} from '../helpers';

const useCalendar = (productCode, currentMonth, focus) => {
  const moment = useLocalisedMoment();
  const { setValue, getValues, watch } = useFormContext();
  const dispatch = useDispatch();

  const [disabledPickedDateReason, setDisabledPickedDateReason] =
    useState(null);
  const [availableSelectionRange, setAvailableSelectionRange] = useState([]);
  const [restrictedDates, setRestrictedDates] = useState([]);
  const specialCodeType = watch('specialCodeType');
  const specialCodeValue = watch('specialCodeValue');
  const rateCode = watch('rateCode');
  const agentId = watch('agentId');
  const guestsPerRoom = watch('guestsPerRoom');

  const calendarAvailability = useSelector(
    (state) => state.availability.calendar
  );

  const numRooms = guestsPerRoom.length;

  const { printPrice } = usePrintPrice(productCode);

  const selectedRange = getSelectedRange(
    getValues('startDate'),
    getValues('endDate'),
    moment
  );

  const isDayBlocked = (day) =>
    getIsDayBlocked(
      day,
      calendarAvailability.isLoading,
      calendarAvailability.list,
      focus,
      availableSelectionRange,
      moment
    );

  const renderDayContents = (day) => {
    const side =
      day.format('MM') === moment(currentMonth).format('MM') ? 'left' : 'right';

    return (
      <DayContents
        day={day}
        daysAvailability={calendarAvailability.list}
        availableSelectionRange={availableSelectionRange}
        restrictedDates={restrictedDates}
        selectedRange={selectedRange}
        isLoading={calendarAvailability.isLoading}
        focus={focus}
        printPrice={printPrice}
        numRooms={numRooms}
        side={side}
      />
    );
  };

  const handleCloseCalendar = () => {
    if (!getValues('endDate')) {
      setValue(
        'endDate',
        moment(getValues('startDate')).add(1, 'day').format('YYYY-MM-DD')
      );
    }
  };

  const handleDateChange = ({ startDate: sDate, endDate: eDate }) => {
    const isStartDateChanged =
      focus === 'startDate' && sDate !== getValues('startDate');

    if (!eDate || isStartDateChanged) {
      setValue('startDate', sDate.format('YYYY-MM-DD'), { shouldDirty: true });
      setValue('endDate', '', { shouldDirty: true });
    } else {
      setValue('startDate', sDate.format('YYYY-MM-DD'), { shouldDirty: true });
      setValue('endDate', eDate.format('YYYY-MM-DD'), { shouldDirty: true });
    }
  };

  // when the first day is selected
  // limit available days up to the next unavailable day

  useEffect(() => {
    if (focus === 'endDate') {
      const selectRange = getAvailableSelectRange(
        moment(getValues('startDate')).format('YYYY-MM-DD'),
        [...calendarAvailability.list],
        moment
      );

      const [availableSelectionRangeWithRestrictions, restrictedDates] =
        getSelectedRangeAfterRestrictions(
          selectRange,
          calendarAvailability.list,
          moment
        );

      setAvailableSelectionRange(availableSelectionRangeWithRestrictions);
      setRestrictedDates(restrictedDates);
    }
  }, [focus, getValues, calendarAvailability.list, moment]);

  useEffect(() => {
    setDisabledPickedDateReason(null);
    dispatch(setCurrentCalendarMonth(currentMonth));
  }, [currentMonth, dispatch]);

  useEffect(() => {
    const disabledPickedDateReasonValue = checkIfDisabledDateIsPicked(
      getValues('startDate'),
      getValues('endDate'),
      calendarAvailability.list,
      moment
    );

    setDisabledPickedDateReason(disabledPickedDateReasonValue);
  }, [currentMonth, calendarAvailability.list, getValues, moment]);

  return {
    disabledPickedDateReason,
    renderDayContents,
    handleDateChange,
    handleCloseCalendar,
    isDayBlocked,
    specialCodeApplied: specialCodeType && specialCodeValue,
    rateCodeApplied: rateCode,
    agentId,
  };
};

export default useCalendar;
