import { paymentTypeMap } from '../../constants';
import titleCase from '../../helpers/title-case';

const purchase = ({ bookings, paymentBrand = '', orderReference, hotel }) => {
  if (!window.dataLayer) return;

  const totalTax = bookings.reduce((acc, booking) => {
    const bookingTotal =
      booking.roomRate.price - booking.roomRate.totalAmountBeforeTax;
    return acc + bookingTotal;
  }, 0);

  const totalExcludngTax = bookings.reduce(
    (acc, booking) => acc + booking.roomRate.totalAmountBeforeTax,
    0
  );

  const totalIncludingTax = bookings.reduce((acc, { roomRate: { price } }) => {
    return acc + price;
  }, 0);

  const checkoutPaymentType =
    paymentTypeMap[paymentBrand] || titleCase(paymentBrand);

  const data = {
    event: 'purchase',
    currencyCode: hotel.currency,
    checkoutPaymentType,
    numGuests: bookings.reduce(
      (acc, booking) => acc + booking.numAdults + booking.children.length,
      0
    ),
    numRooms: bookings.length,
    transactionCoupon:
      bookings.find(({ specialCodeValue }) => !!specialCodeValue)
        ?.specialCodeValue || '',
    transactionId: orderReference,
    transactionTax: totalTax,
    transactionTotal: totalExcludngTax,
    transactionTotalIncludingTaxes: totalIncludingTax,
    products: bookings.map((booking) => ({
      brand: 'Belmond',
      category: booking.roomRate.name,
      checkinDate: booking.startDate,
      checkoutDate: booking.endDate,
      city: hotel.address.city,
      country: hotel.address.countryName,
      id: booking.roomType.title,
      name: hotel.shortName,
      price: booking.roomRate.price / booking.roomRate.pricesPerNight.length, // average price per night
      productType: 'Hotel',
      quantity: booking.roomRate.pricesPerNight.length,
    })),
  };
  window.dataLayer.push(data);
};

export default purchase;
