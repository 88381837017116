import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const ScreenReadyContext = createContext();

export const ScreenReadyProvider = ({ children }) => {
  const [isScreenReady, setIsScreenReady] = useState(false);

  const screenReaderValue = useMemo(
    () => ({ isScreenReady, setIsScreenReady }),
    [isScreenReady, setIsScreenReady]
  );

  return (
    <ScreenReadyContext.Provider value={screenReaderValue}>
      {children}
    </ScreenReadyContext.Provider>
  );
};

ScreenReadyProvider.propTypes = {
  children: PropTypes.node,
};

export const useScreenReady = () => {
  const { isScreenReady, setIsScreenReady } = useContext(ScreenReadyContext);

  useEffect(() => {
    return () => setIsScreenReady(false);
  }, [setIsScreenReady]);

  return { isScreenReady, setIsScreenReady };
};
