import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import CalendarFeedback from '../CalendarFeedback/CalendarFeedback';
import styles from './CalendarLegend.module.css';

const CalendarLegend = ({
  disabledPickedDateReason,
  isSpecialCode,
  multiRooms,
}) => {
  const { t } = useTranslate();
  return (
    <div>
      <div className={styles.CalendarLegend__container}>
        <div className="d-flex align-items-center">
          {/* <div className={styles.CalendarLegend__checkOut}></div> */}
          <div className={styles.CalendarLegend__unavailable}></div>
          {t('Check-out only')}
        </div>
        {/* TODO: TEMP-CALENDAR-FILE */}
        {/* <div className="d-flex align-items-center mx-3">
          <div className={styles.CalendarLegend__unavailable}></div>
          {t(
            'Dates are unavailable but we may be able to accommodate you.'
          )}{' '}
          {t('Please, contact the hotel directly.')}
        </div> */}
      </div>
      <CalendarFeedback
        disabledPickedDateReason={disabledPickedDateReason}
        isSpecialCode={isSpecialCode}
        multiRooms={multiRooms}
      />
    </div>
  );
};
CalendarLegend.propTypes = {
  disabledPickedDateReason: PropTypes.string,
  isSpecialCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  multiRooms: PropTypes.bool,
};
export default CalendarLegend;
