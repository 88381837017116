import mergeAvailabilityMetadata from './merge-availability-metadata';

const determineAvailabilityMetadata = (month, monthData, numRooms, moment) => {
  const daysInMonth = moment(month).daysInMonth();

  // TODO: TEMP-CALENDAR-FIX
  // const availableDates = monthData.map(({ date }) => date);

  const monthDayAvailability = Array.from({ length: daysInMonth }).map(
    (_, d) => {
      d = d + 1;
      const dayString = d.toString().padStart(2, '0');
      const previousDayString = (d - 1).toString().padStart(2, '0');

      const {
        minimumPrice,
        availabilityForRooms = [],
        available,
      } = monthData.find(({ date }) => date === dayString) || {};

      // TODO: TEMP-CALENDAR-FIX
      const { available: availablePreviousDay } =
        monthData.find(({ date }) => date === previousDayString) || {};

      const { availabilityForRooms: availabilityForRoomsPreviousDay = [] } =
        monthData.find(({ date }) => date === previousDayString) || {};

      const availableForAllRooms = numRooms === availabilityForRooms.length;
      const availableForAllRoomsPreviousDay =
        numRooms === availabilityForRoomsPreviousDay.length;

      const metadata = mergeAvailabilityMetadata(availabilityForRooms);

      // if the date is 2024-12-29, debug

      // TODO: TEMP-CALENDAR-FIX
      const isCheckIn = Boolean(
        available && availableForAllRooms && !metadata.NoArrive
      );

      // TODO: TEMP-CALENDAR-FIX
      const isCheckOut = Boolean(
        availablePreviousDay && availableForAllRoomsPreviousDay
      );

      const dayData = {
        date: `${month}-${dayString}`,
        isCheckIn,
        isCheckOut,
        metadata,
      };

      if (minimumPrice && availableForAllRooms) {
        dayData.minimumPrice = minimumPrice;
      }

      return dayData;
    }
  );

  return monthDayAvailability;
};

export default determineAvailabilityMetadata;
