const navigateAfterRemovingAllBookings = (navigateFn, bookings) => {
  if (
    bookings.length === 1 ||
    !bookings.slice(1).some((b) => b.productCode !== bookings[0].productCode)
  ) {
    const booking = bookings[0];
    let path = `/select-room?productCode=${booking.productCode}`;

    if (booking.agentId) {
      path += `&agentId=${booking.agentId}`;
      if (booking.agentCrmId) {
        path += `&agentCrmId=${booking.agentCrmId}`;
      }
    }
    if (booking.specialCodeType && booking.specialCodeValue) {
      path += `&specialCodeType=${booking.specialCodeType}&specialCodeValue=${booking.specialCodeValue}`;
    }
    navigateFn(path, { replace: true });
  } else {
    navigateFn('/', { replace: true });
  }
};

export default navigateAfterRemovingAllBookings;
