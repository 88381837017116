import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/Cross.svg';
import AccesibilityElement from '../../../../components/AccesibilityElement/AccesibilityElement';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import LoadingButton from '../../../../components/LoadingButton/LoadingButton';
import SelectComponent from '../../../../components/SelectComponent/SelectComponent';
import { gtmBookingInteraction } from '../../../../gtm/events';
import validateAgentCode from '../../../../helpers/validate-agent-code';
import validateSpecialCode from '../../../../helpers/validate-special-code';
import useAxios from '../../../../hooks/useAxios/useAxios';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import {
  fetchAgentProfile,
  removeAgentProfile,
  setAgentProfileRatePlanAssociated,
} from '../../../../redux/slices/bookingsSlice/bookingsSlice';
import styles from './SpecialCode.module.css';

const SpecialCode = ({
  onClose,
  productCode,
  mobile,
  onApply,
  onRemoveCode,
}) => {
  const { t } = useTranslate();
  const axios = useAxios();

  const specialCodeTypes = {
    promoCode: t('Promo Code'),
    groupCode: t('Group Code'),
  };

  const dispatch = useDispatch();

  const {
    getValues: getParentFormValues,
    setValue: setParentFormValue,
    watch: watchParentFormValue,
  } = useFormContext();

  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      specialCodeType: getParentFormValues('specialCodeType'),
      specialCodeValue: getParentFormValues('specialCodeValue'),
      agentId: getParentFormValues('agentId'),
    },
    mode: 'all',
  });

  const countryCode = useSelector((state) => state.appSettings.countryCode);

  const parentSpecialCodeType = watchParentFormValue('specialCodeType');
  const parentAgentId = watchParentFormValue('agentId');
  const agentId = watch('agentId');
  const specialCodeType = watch('specialCodeType');
  const specialCodeValue = watch('specialCodeValue')?.toUpperCase();

  const handleSpecialCode = async (params) => {
    if (!countryCode) return;

    try {
      const { isValid, lowestMinStay } = await validateSpecialCode(
        params,
        axios,
        countryCode
      );

      if (!isValid) {
        setError('specialCodeValue', {
          type: 'manual',
          message: t('Special code is not valid.'),
        });
      }
      if (lowestMinStay) {
        setParentFormValue(
          'endDate',
          moment(getParentFormValues('startDate'))
            .add(lowestMinStay, 'days')
            .format('YYYY-MM-DD')
        );
      }

      return isValid;
    } catch (e) {
      setError('specialCodeValue', {
        type: 'manual',
        message: `${t('Something went wrong.')} ${t('Please try again.')}`,
      });

      return false;
    }
  };

  const isCodeValid = async () => {
    let isAllValid = true;
    let ratePlanAssociated = null;
    const params = {
      productCode,
      startDate: getParentFormValues('startDate'),
      endDate: getParentFormValues('endDate'),
      numAdults: 2, // just use 1 room and 2 adults to check code validitiy
      specialCodeType,
      specialCodeValue,
      agentId,
    };

    if (specialCodeValue && specialCodeTypes) {
      isAllValid = await handleSpecialCode(params);
    }

    if (agentId) {
      try {
        const validation = await validateAgentCode(params, axios);
        const { valid } = validation;
        ratePlanAssociated = validation.ratePlanAssociated;

        dispatch(setAgentProfileRatePlanAssociated(ratePlanAssociated));

        if (!valid) {
          setError('agentId', {
            type: 'manual',
            message: t('Agent ID is not valid.'),
          });
        }

        isAllValid = isAllValid && valid;
      } catch (e) {
        setError('agentId', {
          type: 'manual',
          message: `${t('Something went wrong.')} ${t('Please try again.')}`,
        });
      }
    }

    return { valid: isAllValid, ratePlanAssociated };
  };

  const handleSubmitButtonClick = async () => {
    if (!specialCodeType && !agentId) return;

    gtmBookingInteraction('Code Apply');
    setLoading(true);

    const { valid, ratePlanAssociated = false } = await isCodeValid();

    if (valid) {
      const agentId = getValues('agentId');
      if (agentId) {
        dispatch(fetchAgentProfile(agentId, axios));
      } else {
        dispatch(removeAgentProfile());
      }

      setParentFormValue('specialCodeType', getValues('specialCodeType'), {
        shouldDirty: true,
      });
      setParentFormValue(
        'specialCodeValue',
        getValues('specialCodeValue')?.toUpperCase(),
        {
          shouldDirty: true,
        }
      );
      setParentFormValue('agentId', agentId, { shouldDirty: true });

      onApply(ratePlanAssociated);
    }
    setLoading(false);
  };

  const handleRemoveSpecialCodeButtonClick = () => {
    setParentFormValue('specialCodeType', '', { shouldDirty: true });
    setParentFormValue('specialCodeValue', '');
    onRemoveCode();
  };

  const handleRemoveAgentIdButtonClick = () => {
    dispatch(removeAgentProfile());
    setParentFormValue('agentId', '', { shouldDirty: true });
    onRemoveCode();
  };

  useEffect(() => {
    setValue('specialCodeValue', '');
    clearErrors('specialCodeValue');
  }, [setValue, specialCodeType, clearErrors]);

  return (
    <Form className={styles.SpecialCode__container} noValidate>
      {parentSpecialCodeType ? (
        <div className="mb-4 d-flex justify-content-between align-items-center gap-4">
          {specialCodeTypes[getParentFormValues('specialCodeType')]}:{' '}
          {getParentFormValues('specialCodeValue')}
          <button
            onClick={() => handleRemoveSpecialCodeButtonClick()}
            aria-label="deleteCode"
            type="button"
          >
            <CrossIcon />
          </button>
        </div>
      ) : (
        <>
          <SelectComponent
            watch={watch}
            control={control}
            errors={errors}
            name="specialCodeType"
            setValue={setValue}
            label={t('Special codes')}
            rules={
              !!specialCodeValue && {
                required: t('This field is required'),
              }
            }
            options={[
              { code: 'promoCode', option: t('Promo Code') },
              { code: 'groupCode', option: t('Group Code') },
            ]}
            placeholderOption={t('Code Type')}
          />

          <div className="position-relative my-4 w-auto">
            <InputComponent
              control={control}
              errors={errors}
              rules={
                !!specialCodeType && {
                  required: t('This field is required'),
                }
              }
              name="specialCodeValue"
              label={t('Enter code')}
              inputClassName="text-uppercase"
            />

            {!errors.specialCodeValue && specialCodeValue && (
              <AccesibilityElement
                tagName="div"
                ariaLabel="remove code value"
                className={styles.SpecialCode__crossIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setValue('specialCodeValue', '');
                }}
              >
                <CrossIcon />
              </AccesibilityElement>
            )}
          </div>
        </>
      )}
      {parentAgentId ? (
        <div className="my-4 d-flex justify-content-between align-items-center gap-4">
          <div>
            {t('Agent Id')}: {getParentFormValues('agentId')}
          </div>
          <button
            onClick={() => handleRemoveAgentIdButtonClick()}
            aria-label="deleteCode"
            type="button"
          >
            <CrossIcon />
          </button>
        </div>
      ) : (
        <div className="position-relative">
          <InputComponent
            control={control}
            errors={errors}
            name="agentId"
            label={t('Agent Id')}
          />
          {!errors.agentId && agentId && (
            <AccesibilityElement
              tagName="div"
              ariaLabel="remove code value"
              className={styles.SpecialCode__crossIcon}
              onClick={(e) => {
                e.stopPropagation();
                setValue('agentId', '');
              }}
            >
              <CrossIcon />
            </AccesibilityElement>
          )}
        </div>
      )}

      <div
        className={`d-flex align-items-center justify-content-between mt-4 gap-2 ${
          mobile && 'flex-column-reverse'
        }`}
      >
        <Button
          className="hoverEffectButton mx-1"
          onClick={onClose}
          aria-label="Close promo picker"
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          className="button"
          ariaLabel="Apply changes"
          onClick={handleSubmit(handleSubmitButtonClick)}
          type="button"
          loading={loading}
        >
          {t('Apply')}
        </LoadingButton>
      </div>
    </Form>
  );
};

SpecialCode.propTypes = {
  onClose: PropTypes.func,
  mobile: PropTypes.bool,
  productCode: PropTypes.string,
  onApply: PropTypes.func,
  onRemoveCode: PropTypes.func,
};

export default SpecialCode;
