export const mapBookings = (bookings) =>
  bookings.map(
    ({
      id,
      encryptedEmail,
      paymentMethod,
      booking: {
        status,
        startDate,
        endDate,
        checkInTime,
        checkOutTime,
        numAdults,
        children,
        agent,
        groupCode,
        promoCode,
        roomType,
        synxisRatePlanCode,
        roomTypeCode,
        roomRates,
        grandTotal,
        additionalServices,
        isMarketingOptin,
        serviceTotal,
        cancellationPolicy,
        guarantee,
        cancelTimeIn,
        commissionPolicy,
        roomCategoryName,
        rateCurrency,
      },
      property: { code: productCode },
      secondaryGuests,
      guest: {
        firstName,
        lastName,
        personPreNameAdjunct,
        email,
        phone,
        notes,
        address,
      },
    }) => {
      const addonTotal = additionalServices.reduce(
        (acc, { priceWithoutTax, taxAmount }) =>
          acc + priceWithoutTax + taxAmount,
        0
      );
      return {
        id,
        encryptedEmail,
        productCode,
        status,
        paymentMethod,
        guest: {
          firstName,
          lastName,
          prefix: personPreNameAdjunct,
          email,
          phone,
          address,
          notes,
        },
        isMarketingOptin,
        secondaryGuests: secondaryGuests.map(({ firstName, lastName }) => ({
          firstName,
          lastName,
        })),
        roomType: {
          title: roomType,
          category: { name: roomCategoryName },
          code: roomTypeCode,
        },
        roomRate: {
          currencyCode: rateCurrency,
          code: synxisRatePlanCode,
          name: roomRates[0].ratePlanDesc,
          price: grandTotal > 0 ? grandTotal - addonTotal : 0,
          cancellationPolicy,
          bookingPolicy: guarantee,
          commissionPolicy,
          cancelTimeIn,
          serviceCharge: serviceTotal,
          totalAmountBeforeTax:
            (grandTotal > 0 ? grandTotal - addonTotal : 0) - serviceTotal,
          pricesPerNight: roomRates.map((rate) => ({
            date: rate.date.replaceAll('-', '/'),
            price: rate.charge,
          })),
        },
        addons: additionalServices.map((addon) => ({
          code: addon.itemCode,
          price: addon.priceWithoutTax + addon.taxAmount,
          title: addon.itemDesc,
          currency: addon.currency,
        })),
        addonsPriceTotal: addonTotal,
        startDate,
        endDate,
        checkInTime,
        checkOutTime,
        numAdults,
        children,
        specialCodeType: groupCode
          ? 'groupCode'
          : promoCode
          ? 'promoCode'
          : null,
        specialCodeValue: groupCode || promoCode || null,
        agentId: agent?.id,
        agentCrmId: agent?.crmId,
        agent,
      };
    }
  );
