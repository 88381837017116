import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/images/icons/ArrowUp.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/Calendar.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/images/icons/Clock.svg';
import { ReactComponent as DollarIcon } from '../../../../assets/images/icons/DollarIcon.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/images/icons/Info.svg';
import { ReactComponent as PresentIcon } from '../../../../assets/images/icons/Present.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/images/icons/Profile.svg';
import AccesibilityElement from '../../../../components/AccesibilityElement/AccesibilityElement';
import Policies from '../../../../components/Policies/Policies';
import formatRoomName from '../../../../helpers/formatRoomName/formatRoomName';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import usePrintPrice from '../../../../hooks/usePrintPrice/usePrintPrice';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import { bookingStatuses } from '../../../../redux/slices/bookingsSlice/bookingsSlice';
import AddonsList from '../AddonsList/AddonsList';
import styles from './BookingConfirmationDetails.module.css';

const { CANCELLED, CONFIRMED } = bookingStatuses;

const BookingConfirmationDetails = ({
  booking,
  pendingAddonsActions,
  bookingIndex,
  bookingsLength,
  defaultExpanded,
  onAddAddon,
  onRemoveAddon,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();

  const [detailsExpanded, setDetailsExpanded] = useState(defaultExpanded);
  const [rateDetailsByNightOpen, setRateDetailsByNightOpen] = useState(false);
  const { printPrice } = usePrintPrice(booking.productCode);

  const policyList = {
    bookingPolicy: [{ description: booking.roomRate.bookingPolicy }],
    cancellationPolicy: [{ description: booking.roomRate.cancellationPolicy }],
    commissionPolicy: [{ description: booking.roomRate.commissionPolicy }],
  };

  const isCancellable =
    moment(booking.startDate).diff(moment(), 'days') >
    booking.roomRate.cancelTimeIn;

  const childrenText =
    booking.children?.length > 1 ? t('children') : t('child');

  return (
    <>
      <div
        className={styles.BookingConfirmationDetails__title}
        data-testid="roomName"
      >
        {booking.roomType.title}
        {bookingsLength > 1 && (
          <div className={styles.BookingConfirmationDetails__roomNumber}>
            {t('Room')} {bookingIndex + 1} {t('of')} {bookingsLength}
          </div>
        )}
      </div>
      <div>
        <Row className="d-flex justify-content-between">
          <Col md="9" className="d-flex align-items-center my-4">
            <InfoIcon
              className="flex-shrink-0"
              style={{ marginRight: '10', minWidth: 20 }}
            />
            <div
              className={styles.BookingConfirmationDetails__bookingReference}
            >
              {t('Booking Reference')}{' '}
              {bookingsLength > 1 ? `${t('Room')} ${bookingIndex + 1}` : ''}
              <Row style={{ marginLeft: 5 }}>
                <Col
                  className="OneLinkNoTx"
                  style={{
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                    whiteSpace: 'nowrap',
                  }}
                >
                  #{booking.id}-{booking.productCode}
                </Col>
                {booking.status === CANCELLED && (
                  <Col className={styles.BookingConfirmationDetails__cancelled}>
                    {t('Cancelled')}
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col md="3" className="d-flex justify-content-end mb-4">
            <Button
              className="button transparent"
              aria-label="toogle booking details"
              onClick={() => setDetailsExpanded((prev) => !prev)}
            >
              {detailsExpanded ? (
                <div className="d-flex justify-content-between align-items-center">
                  {t('Hide Details')} <ArrowUpIcon style={{ marginLeft: 5 }} />
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  {t('View Details')}{' '}
                  <ArrowDownIcon style={{ marginLeft: 5 }} />
                </div>
              )}
            </Button>
          </Col>
        </Row>
      </div>
      {detailsExpanded && (
        <>
          <div data-testid="expanded-details">
            <hr className={styles.BookingConfirmationDetails__dottedHr} />
          </div>
          <Row className="position-relative mt-4">
            <Col md="4" className="d-flex flex-column mb-5">
              <div className="d-flex flex-row align-items-center mb-2">
                <CalendarIcon
                  className="flex-shrink-0"
                  style={{ marginRight: 14 }}
                />

                <div>
                  <span style={{ marginRight: 5, fontWeight: 600 }}>
                    {t('Arrival')}
                  </span>
                  {moment(booking.startDate).format('ddd, MMM D, YYYY')}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <CalendarIcon
                  className="flex-shrink-0"
                  style={{ marginRight: 14 }}
                />
                <div>
                  <span style={{ marginRight: 5, fontWeight: 600 }}>
                    {t('Departure')}
                  </span>
                  {moment(booking.endDate).format('ddd, MMM D, YYYY')}
                </div>
              </div>
            </Col>
            <Col md="4" className="d-flex text-nowrap align-items-start mb-5">
              <ClockIcon
                className="flex-shrink-0"
                style={{ marginRight: 14 }}
              />
              <div className="d-flex flex-column">
                <div className="mb-2">
                  <span style={{ fontWeight: 600 }}>{t('Check in')}</span>{' '}
                  {t('after')} {booking.checkInTime}
                </div>
                <div>
                  <span style={{ fontWeight: 600 }}>{t('Check out')}</span>{' '}
                  {t('by')} {booking.checkOutTime}
                </div>
              </div>
            </Col>
            <Col md="4" className="d-flex mb-5">
              <ProfileIcon
                className="flex-shrink-0"
                style={{ marginRight: 14 }}
              />
              <div>
                {booking.numAdults}{' '}
                {booking.numAdults > 1 ? t('adults') : t('adult')}
                {booking.children?.length
                  ? `, ${booking.children.length} ${childrenText}`
                  : ''}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="4" className="d-flex align-items-start mb-4">
              <InfoIcon className="flex-shrink-0" style={{ marginRight: 11 }} />
              <div className="d-flex flex-column w-100">
                <div className="mb-2" style={{ textTransform: 'capitalize' }}>
                  <span style={{ fontWeight: 600 }}>{t('Room')}:</span>{' '}
                  {formatRoomName(
                    booking.roomType.category.name,
                    booking.roomType.title,
                    booking.specialCodeValue
                  )}
                </div>
                <div>
                  <AccesibilityElement
                    className={
                      styles.BookingConfirmationDetails__accomodationDetails
                    }
                    tagName="div"
                    ariaLabel="Toggle night rates"
                    onClick={() => setRateDetailsByNightOpen((prev) => !prev)}
                  >
                    <div>
                      <span style={{ marginRight: 5 }}>{t('Rate')}:</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      {parse(booking.roomRate.name)}
                      <div>
                        {rateDetailsByNightOpen ? (
                          <ArrowUpIcon style={{ marginLeft: 8 }} />
                        ) : (
                          <ArrowDownIcon style={{ marginLeft: 8 }} />
                        )}
                      </div>
                    </div>
                  </AccesibilityElement>
                </div>
                {rateDetailsByNightOpen && (
                  <div>
                    <hr
                      className={styles.BookingConfirmationDetails__dottedHr}
                    />
                    {booking.roomRate.pricesPerNight.map(({ date, price }) => (
                      <div
                        className="d-flex justify-content-between"
                        key={`${date}-${price}`}
                      >
                        <div>{moment(date).format('ddd, MMM D, YYYY')}</div>
                        <div>{printPrice(price)}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
            <Col md="4" className="d-flex align-items-start mb-4">
              <ProfileIcon
                className="flex-shrink-0"
                style={{ marginRight: 14, minWidth: 17 }}
              />
              <div className="d-flex flex-column">
                <span style={{ fontWeight: 600 }}>
                  {t('Guest Information')}
                </span>
                <div>
                  {booking.guest.firstName} {booking.guest.lastName}
                </div>
                <div className="OneLinkNoTx">{booking.guest.email}</div>
                <div>{booking.guest.phone}</div>
              </div>
            </Col>
            {booking.paymentMethod?.maskedNumber && (
              <Col md="4" className="d-flex align-items-start mb-4">
                <DollarIcon
                  className="flex-shrink-0"
                  style={{ marginRight: 11 }}
                />
                <div className="d-flex flex-column">
                  <span style={{ fontWeight: 600 }}>{t('Payment method')}</span>
                  <div>
                    {t('Card ending in')} {booking.paymentMethod?.maskedNumber}
                  </div>
                </div>
              </Col>
            )}
          </Row>
          {booking.addons.length > 0 && (
            <Row>
              <Col className="my-4 d-flex justify-content-start align-items-center gap-3">
                <PresentIcon className="flex-shrink-0" />
                <div className="mt-1">
                  <span style={{ fontWeight: 600 }}>
                    {t('Extra')}
                    {booking.addons.length > 1 ? 's' : ''}:
                  </span>{' '}
                  {booking.addons.map((addon, i) => (
                    <Fragment
                      key={`booking-addons-${booking.id}-${booking.productCode}-${addon.code}`}
                    >
                      <span>{addon.title}</span>
                      {i !== booking.addons.length - 1 && (
                        <span style={{ fontWeight: 600, margin: '0 5px' }}>
                          {' '}
                          •{' '}
                        </span>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Col>
            </Row>
          )}
          {!isCancellable && (
            <Row>
              <div className="d-flex align-items-center my-4">
                <InfoIcon
                  className="flex-shrink-0"
                  style={{ marginRight: 11 }}
                />
                <div className="bold">
                  {t(
                    'Please contact the property to modify or cancel this reservation.'
                  )}
                </div>
              </div>
            </Row>
          )}

          <div>
            <hr className="bbe-hr" />
          </div>

          <div style={{ padding: '20px 10px' }}>
            <h3 style={{ paddingBottom: 20 }}>{t('Policies')}</h3>
            <Policies
              policies={policyList}
              loading={false}
              showCommission={!!booking?.agentId && booking?.agentCrmId}
            />
          </div>
          <div>
            <hr className="bbe-hr" />
          </div>

          <div className={styles.BookingConfirmationDetails__priceContainer}>
            <div className="text-uppercase">{t('Total')}</div>
            <div>
              <div className="d-flex justify-content-end">
                {
                  printPrice({
                    value: booking.roomRate.price + booking.addonsPriceTotal,
                    baseCurrency: booking.roomRate.currencyCode,
                  }).price
                }
              </div>
              <div style={{ fontWeight: 400 }}>({t('tax included')})</div>
            </div>
          </div>
          {booking.status === CONFIRMED &&
            !['COMP', 'DISC'].includes(booking.specialCodeValue) && (
              <AddonsList
                booking={booking}
                pendingAddonsActions={pendingAddonsActions}
                onAddAddon={onAddAddon}
                onRemoveAddon={onRemoveAddon}
              />
            )}
        </>
      )}
    </>
  );
};

BookingConfirmationDetails.propTypes = {
  booking: PropTypes.object.isRequired,
  pendingAddonsActions: PropTypes.object,
  bookingIndex: PropTypes.number.isRequired,
  bookingsLength: PropTypes.number.isRequired,
  defaultExpanded: PropTypes.bool,
  onAddAddon: PropTypes.func,
  onRemoveAddon: PropTypes.func,
};

export default BookingConfirmationDetails;
