import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './CheckTime.module.css';

const CheckTime = ({ checkInTime, checkOutTime }) => {
  const { t } = useTranslate();

  return (
    <div className={styles.CheckTime__checkTime}>
      <div className={styles.CheckTime__checkIn}>
        {t('Check-in')}
        <span>
          {t('After')} {checkInTime?.slice(0, 2)}:{checkInTime?.slice(2, 4)}
        </span>
      </div>
      <div className={styles.CheckTime__checkOut}>
        {t('Check-out')}
        <span>
          {t('Before')} {checkOutTime?.slice(0, 2)}:{checkOutTime?.slice(2, 4)}
        </span>
      </div>
    </div>
  );
};

CheckTime.propTypes = {
  checkInTime: PropTypes.string,
  checkOutTime: PropTypes.string,
};

export default CheckTime;
